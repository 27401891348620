import { useContext } from "react";
import { Grid } from "@material-ui/core";
import YachtsCard from "./yachts-card";
import { ourYachtsData } from "../../data";
import {Helmet} from "react-helmet";

function OurYachts() {

    return (
        <>
        <Helmet>
        <title>Noon Yacht | Our Yachts</title>
        <meta name="description" content="Noon Yacht is Dubai's Favorite Award Winning Yacht Rental Dubai Company, Book Online." />
        <meta name="keywords" content="Noon Yacht, Noon Yacht Dubai,yachts,xclusive yachts,yachts in dubai marina,princess yachts,marina yachts,
        dubai marina yachts,luxury yachts,majesty yachts,azimut yachts,royal yachts,yachts dubai,luxurious yachts,
        yachts in dubai,yachts for rent,cozmo yachts,superyacht,boat charter,mega yacht,yacht charter,sailing yacht
        yacht broker,birthday parties on yachts,birthday yachts,birthday in yacht,happy birthday yachts,birthday party in yacht dubai,
        wedding yachts miami,wedding on a yacht dubai,wedding charter yachts,wedding yachts in miami,wedding yacht dubai,
        mega yachts dubai,mega yachts for charter,mega yachts and superyachts,fishing in dubai,fishing abu dhabiyachts rental dubai,yachts rental,yachts rental near me,yachts rental in miami,adonia yachts rental lake powell,
adonia yachts rental,ariel yachts rental,azimut yachts rental,a yacht rental,yachts rental miami beach,yachts and boats rental dubai,nanje rental yachts dubai,
yachts to rent in dubai,yacht rental uae,yachts for rental,yachts for rental near me,yachts for rental in miami,yachts for rental in dubai,
luxury yachts for rental,yachts rental in dubai,yachts rental in abu dhabi,yacht rent dubai,yacht charters dubai,luxury yachts rental,
yachts rental miami,luxury yachts rental miami,luxury yachts rental marina,mega yachts rental,yacht rent in abu dhabi,party yacht rental,yachts rentals,
yachts rentals near me,yachts rentals dubai,vacation rental yachts,wajer yachts rental,xclusive yachts rental dubai,yacht booking abu dhabi,yacht to rent dubai,
yachts dubai rent" />
    </Helmet>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <YachtsCard
                    data={ourYachtsData}
                />
            </Grid>
        </Grid>
        </>
    )
}

export default OurYachts;
