import {
    Grid,
    Container,
    ButtonBase,
    Button,
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useHistory } from "react-router-dom";
import { homeExplores } from "../../data"

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        minWidth: 300,
        width: '100%',
    },
    image: {
        position: 'relative',
        height: 300,
        textTransform: 'uppercase',
        [theme.breakpoints.down('xs')]: {
            width: '100% !important', // Overrides inline-style
            height: 100,
        },
        '&:hover, &$focusVisible': {
            zIndex: 1,
            '& $imageBackdrop': {
                opacity: 0.15,
            },
            '& $imageMarked': {
                opacity: 0,
            },
            '& $imageTitle': {
                border: '4px solid currentColor',
                textTransform: 'uppercase'
            },
        },
    },
    focusVisible: {},
    imageButton: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
    },
    imageSrc: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center 40%',
    },
    imageBackdrop: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.black,
        opacity: 0.4,
        transition: theme.transitions.create('opacity'),
    },
    imageTitle: {
        position: 'relative',
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
    },
    imageMarked: {
        height: 3,
        width: 18,
        backgroundColor: theme.palette.common.white,
        position: 'absolute',
        bottom: -2,
        left: 'calc(50% - 9px)',
        transition: theme.transitions.create('opacity'),
    },
    styleHeading: {
        textAlign: 'center',
        marginTop: '40px',
    },
    styleLine: {
        width: '100px',
        backgroundColor: 'orange'
    },
}));

const CategoryRow = ({ homeExplore, row }) => {
    const classes = useStyles();
    const history = useHistory();

    return (
        <Grid container spacing={4}  style={{ marginTop: '40px' }}>
            <Grid item lg={6} xs={12} md={6} style={{ order: row % 2 ? 2 : 1 }}>
                
                        <Button
                            focusRipple
                            className={classes.image}
                            focusVisibleClassName={classes.focusVisible}
                            style={{ width: '100%' }}
                            onClick={() => history.push('/contact-us')}

                        >
                            <span
                                className={classes.imageSrc}
                                style={{
                                    backgroundImage: `url(${homeExplore.image})`,
                                }}
                            />
                            <span className={classes.imageBackdrop} />
                            <span className={classes.imageButton}>
                                <Typography
                                    component="span"
                                    variant="subtitle1"
                                    color="inherit"
                                    className={classes.imageTitle}
                                >
                                    {homeExplore.title}
                                    <span className={classes.imageMarked} />
                                </Typography>
                            </span>
                        </Button>
                    
            </Grid>
            <Grid item lg={6} xs={12} md={6} style={{ order: row % 2 ? 1 : 2 }}>
                <p style={{ marginTop: '100px' }}>{homeExplore.description}</p>
                
            </Grid>
        </Grid>
    )
}

export default function HomeExplore() {
    const classes = useStyles();
    return (
        <Container>
            {homeExplores.map((homeExplore, index) => <CategoryRow key={index} homeExplore={homeExplore} row={index} />)}
        </Container>
    )
}
