import {
    Grid,
    Container,
    Typography
} from "@material-ui/core";
import {Helmet} from "react-helmet";
import ExploreYachts from "./explore-yachts";
import HomeExplore from "./home-data";
import { ourYachtsData } from "../../data";
import makeStyles from "@material-ui/core/styles/makeStyles";
import SlickCarousel from "../../components/slick-carousel";

const useStyles = makeStyles({
    image: {
        width: '100%',
        marginTop: '50px',
    },
    data: {
        position: 'absolute',
        marginTop: '30%',
        color: 'whitesmoke',
        fontFamily: 'bodoni',
        margin: '0px auto 0px 70px',
        fontWeight: 'bolder'
    }
})

function Home() {
    const classes = useStyles();

    return (
        <>
        <Helmet>
        <title>Noon Yacht - Yacht Rental Dubai, Luxury Yacht Charter </title>
        <meta name="description" content="Noon Yacht is Dubai's Favorite Award Winning Yacht Rental Dubai Company, Book Online." />
        <meta name="keywords" content="Noon Yacht, Noon Yacht Dubai, yacht rental dubai, boat charter dubai, boat cruises dubai, luxury cruise dubai, yacht rental in dubai, boat trip dubai, sea trip dubai, boat hire dubai, boat hire in dubai, fishing trip dubai, boat rent dubai, boat tour in dubai, yacht charter dubai, dubai sea cruise, private charter dubai, dubai yacht charter, dubai yacht rental, fishing trips dubai, rent boat dubai, boat tour dubai, rent a boat dubai, rent a yacht dubai, sea tour dubai, dubai boat rental, dubai boat rentals, boat rental in dubai, boat hire in dubai, boat rental dubai, boat hire dubai, yacht rental in duabi, boat rentals dubai, yacht charters dubai, boat charter dubai, yacht charters in dubai, rent boat in dubai, rent a boat dubai, rent boat dubai, sea tour dubai, sea trip dubai, yacht charter dubai, yacht charter in dubai, dubai yacht cruise, dubai boat charter, boat hire dubai" />
    </Helmet>
            <Grid container>
                <Grid item md={12} lg={12} sm={12} xs={12}>
                    <SlickCarousel />

                 <ExploreYachts data={ourYachtsData}/>


                    <HomeExplore />
                    
                                
                                <img className={classes.image} src="/images/new-images/homebase.webp" alt=" Noon Yacht " />
                            </Grid>
                        </Grid>
                    
        </>
    )
}

export default Home;
