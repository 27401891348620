import React from 'react'
import {
    Grid,
    Container,
    Typography,
    Button
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import {Helmet} from "react-helmet";
import { TouristAttractionspage } from "../../data";
import { useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    expHeading: {
        textAlign: 'center',
        color: 'darkslateblue'
    },
    serviceHeading: {
        color: 'darkslateblue',
        marginTop: '40px'
    },
    images: {
        width: '100%',
        height: '100%',
        marginTop: '50px',
        borderRadius: '20px'
    },
    heading: {
        marginTop: '50px',
        textAlign: 'center',
        paddingTop: '20px',
        color: '#072a50',
        borderRadius: '20px'
    },
    wideRnage: {
        textAlign: "center",
        marginTop: '80px'
    },
    wideRanageHeading: {
        color: '#ba682e',
    },
    miHeading: {
        color: 'darkslateblue'
    },
    cardImage: {
        width: '265px',
        height: '100%'
    },
    wideRangeCard: {
        marginTop: '30px'
    },
    customerImage: {
        width: '100%',
        marginTop: '50px',
        borderRadius: '20px'
    },
    customerData: {
        textAlign: 'center'
    },
    customerCarousel: {
        marginTop: '200px'
    },
    customerName: {
        color: '#ba682e'
    },
    customerDesignation: {
        color: 'crimson'
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        minWidth: 300,
        width: '100%',
    },
    image: {
        position: 'relative',
        height: 300,
        textTransform: 'uppercase',
        [theme.breakpoints.down('xs')]: {
            width: '100% !important', // Overrides inline-style
            height: 100,
        },
        '&:hover, &$focusVisible': {
            zIndex: 1,
            '& $imageBackdrop': {
                opacity: 0.15,
            },
            '& $imageMarked': {
                opacity: 0,
            },
            '& $imageTitle': {
                border: '4px solid currentColor',
                textTransform: 'uppercase'
            },
        },
    },
    focusVisible: {},
    imageButton: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
    },
    imageSrc: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center 40%',
    },
    imageBackdrop: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.black,
        opacity: 0.4,
        transition: theme.transitions.create('opacity'),
    },
    imageTitle: {
        position: 'relative',
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
    },
    imageMarked: {
        height: 3,
        width: 18,
        backgroundColor: theme.palette.common.white,
        position: 'absolute',
        bottom: -2,
        left: 'calc(50% - 9px)',
        transition: theme.transitions.create('opacity'),
    },
    styleHeading: {
        textAlign: 'center',
        marginTop: '40px'
    },
    styleLine: {
        width: '100px',
        backgroundColor: 'orange'
    },
}));

const CategoryRow = ({ Specialoffer, row }) => {
    const classes = useStyles();
    const history = useHistory();

    return (
        <Grid container spacing={4}  style={{ marginTop: '40px' }}>
            <Grid item lg={6} xs={12} md={6} style={{ order: row % 2 ? 2 : 1 }}>
                
                        <Button
                            focusRipple
                            className={classes.image}
                            focusVisibleClassName={classes.focusVisible}
                            style={{ width: '100%' }}
                            onClick={() => history.push('/contact-us')}

                        >
                            <span
                                className={classes.imageSrc}
                                style={{
                                    backgroundImage: `url(${Specialoffer.image})`,
                                }}
                            />
                            <span className={classes.imageBackdrop} />
                            <span className={classes.imageButton}>
                                <Typography
                                    component="span"
                                    variant="subtitle1"
                                    color="inherit"
                                    className={classes.imageTitle}
                                >
                                    {Specialoffer.title}
                                    <span className={classes.imageMarked} />
                                </Typography>
                            </span>
                        </Button>
                    
            </Grid>
            <Grid item lg={6} xs={12} md={6} style={{ order: row % 2 ? 1 : 2 }}>
            <h3 style={{color:"#005cbf"}}>{Specialoffer.title}</h3>

                <Typography gutterBottom variant='p'> {Specialoffer.description1}</Typography> <br />
                
                <Typography gutterBottom variant='p' > {Specialoffer.description2}</Typography> <br />

                <Typography gutterBottom variant='p' > {Specialoffer.description3}</Typography> <br />
                
                <Typography gutterBottom variant='p' > {Specialoffer.description4}</Typography> <br />
                
                <Typography gutterBottom variant='p' > {Specialoffer.description5}</Typography> <br />
                
                <Typography gutterBottom variant='p' > {Specialoffer.description6}</Typography> <br />
                
                <Typography gutterBottom variant='p' > {Specialoffer.description7}</Typography> <br />
                
              
            </Grid>
        </Grid>
    )
}

export function TouristAttraction() {
    const classes = useStyles();
    return (
        <Container>
            {TouristAttractionspage .map((Specialoffer, index) => <CategoryRow key={index} Specialoffer={Specialoffer} row={index} />)}
        </Container>
    )
}




function TouristAttractions() {
    const classes = useStyles();

    return (
        <>
        <Helmet>
        <title>Noon Yacht | Tourist Attractions</title>
        <meta name="description" content="Noon Yacht are not just a yacht rental company, we are an extraordinary luxury event & luxury yacht rental planning company with a complete package of moments and celebrations under one platform. We have UAE's best Yacht Charter services with  Sunseeker & Benetti Yachts. Jetski & Seabob. " />
        <meta name="keywords" content="Noon Yacht,Noon Yacht dubai,yachts,xclusive yachts,yachts in dubai marina,princess yachts,marina yachts,
        dubai marina yachts,luxury yachts,majesty yachts,azimut yachts,royal yachts,yachts dubai,luxurious yachts,
        yachts in dubai,yachts for rent,cozmo yachts,superyacht,boat charter,mega yacht,yacht charter,sailing yacht
        yacht broker,birthday parties on yachts,birthday yachts,birthday in yacht,happy birthday yachts,birthday party in yacht dubai,
        wedding yachts miami,wedding on a yacht dubai,wedding charter yachts,wedding yachts in miami,wedding yacht dubai,
        mega yachts dubai,mega yachts for charter,mega yachts and superyachts,fishing in dubai,fishing abu dhabiyachts rental dubai,yachts rental,yachts rental near me,yachts rental in miami,adonia yachts rental lake powell,
adonia yachts rental,ariel yachts rental,azimut yachts rental,a yacht rental,yachts rental miami beach,yachts and boats rental dubai,nanje rental yachts dubai,
yachts to rent in dubai,yacht rental uae,yachts for rental,yachts for rental near me,yachts for rental in miami,yachts for rental in dubai,
luxury yachts for rental,yachts rental in dubai,yachts rental in abu dhabi,yacht rent dubai,yacht charters dubai,luxury yachts rental,
yachts rental miami,luxury yachts rental miami,luxury yachts rental marina,mega yachts rental,yacht rent in abu dhabi,party yacht rental,yachts rentals,
yachts rentals near me,yachts rentals dubai,vacation rental yachts,wajer yachts rental,xclusive yachts rental dubai,yacht booking abu dhabi,yacht to rent dubai,
yachts dubai rent" />
    </Helmet>
        <Container >
            <Grid container >
                <Grid item lg={12} md={12} sm={12} xs={12} >
                <TouristAttraction/>
                </Grid>
            </Grid>
        </Container>
        </>
    )
}

export default TouristAttractions;