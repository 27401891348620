

// filter lables
export const homeExplores = [
    {
        id: 1,
        image: '/images/exploreHome/1.webp',
        title: 'Barbecue on Yacht',
        description: 'While on this trip of your luxury private yacht with views of the Dubai Marina district from the yacht cruise across. Experience uninterrupted panoramic views of Dubai skyline with live BBQ on the yacht with at live cooking stations on the deck of the yacht.',
    },
    {
        id: 2,
        image: '/images/exploreHome/2.webp',
        title: 'Wedding in Yacht',
        description: 'With noon yacht you can experience one of the most special luxury yacht wedding that makes them heavenly beauty event on Earth. The best destination for wedding in UAE for the wedding, what could be a better choice than an extravagantly designed private yacht floating in the middle of the sea',
    },
    {
        id: 3,
        image: '/images/exploreHome/3.webp',
        title: 'Birthday on Yacht',
        description: 'We are just not yacht rental company we are extra ordinary event yacht rental planning company with complete package of moments and celebrations, we give our clients and option of choices to select wide range of color themes & exclusive customized products such as flowers bouquet with balloons, customized cakes, which cannot be found in any or with any yacht rental service company.',
    },
    {
        id: 4,
        image: '/images/exploreHome/4.webp',
        title: 'Megayacht Dinner / Lunch',
        description: 'Book a mega yacht to Enjoy best Dubai scenic views of the famous Dubai Marina along with panoramic views of the skyline and Atlantis Hotel and Palm Jumeirah Island from the comfort of a luxury mega yacht. Sit back and taste a five star all-you-can-eat buffet dinner.',
    },
]

// filter lables
export const Specialofferspage = [
    {
        id: 1,
        image: '/images/Specialoffers/1.webp',
        title: 'Birthday Package',
        description1: '•	2 hours yacht cruising',
        description2: '•	Balloon decoration with choice of color theme',
        description3: '•	Exclusive Customized bouquet ',
        description4: '•	Classy cake box with your own choice of flavor',
        description5: '•	Live unlimited BBQ for 15 pax ',
        description6: '•	Un limited soft drinks, water & ice',
        description7: '•	Swimming',
    },
    {
        id: 2,
        image: '/images/Specialoffers/2.webp',
        title: 'Couples Package',
        description1: '•	2 hours yacht cruising',
        description2: '•	Balloon decoration with choice of color theme',
        description3: '•	Rose petals all around',
        description4: '•	Un limited soft drinks, water & ice',
        description5: '•	Swimming',
        description6: '•	Disposable cutlery',
    },
    {
        id: 3,
        image: '/images/Specialoffers/3.webp',
        title: 'Fishing package (10 pax)',
        description1: '•	4 hours fishing',
        description2: '•	Fishing equipment’s',
        description3: '•	Un limited soft drinks, water & ice',
        description4: '•	Swimming',
        description5: '•	Towels ',
        description6: ' ',
    },
    {
        id: 4,
        image: '/images/Specialoffers/4.webp',
        title: '30 pax Exlusive',
        description1: '•	Un limited soft drinks, water & ice ',
        description2: '•	2 hours yacht cruising',
        description3: '•	Live unlimited BBQ for 30 pax ',
        description4: '•	Swimming ',
        description5: '•	Disposable cutlery',
        description6: ' ',
    },
    {
        id: 5,
        image: '/images/Specialoffers/5.webp',
        title: 'The special 8 (8pax)',
        description1: '•	2 hours yacht cruising',
        description2: '•	Unlimited live BBQ ',
        description3: '•	Un limited soft drinks, water & ice',
        description4: '•	Swimming ',
        description5: '•	Disposable cutlery',
        description6: ' ',
    },
]
// filter lables
export const TouristAttractionspage = [
    {
        id: 1,
        image: '/images/TouristAttractions/1.webp',
        title: 'Desert Safari',
        description1: '•	Pick-Up – Our friendly and courteous team will collect you and your group from your hotel located anywhere in Dubai.',
        description2: '•	Tour Briefing – You’ll get a tour guide  in your full desert safari session , who will be taking care of your complete trip',
        description3: '•	Dune Bashing Session – We have 1, 2 or 3-hour dune bashing sessions that provides you with a fully guided, high octane thrill ride ',
        description4: '•	Bedouin Experience – A 2-hour adventure that takes you across the dunes and climaxes with a Bedouin campsite dining experience – including entertainment, shisha, henna, belly dancing and buffet dinner.',
        description5: '•	Refreshments – Throughout your dune bashing session, we provide all our guests with plenty of drinks (non-alcoholic) to keep your energy levels up.',
        description6: ' ',
        description7: ' ',
    },
    {
        id: 2,
        image: '/images/TouristAttractions/2.webp',
        title: 'Dhow Cruise in Marina',
        description1: '•	NOON Dhow cruise is  one of the best  Dinner Cruise lasting 2 hours filled with 100% entertainment and satisfaction. Enjoy the view of the astonishing Marina Skyscrapers along with the stunning views of Marina Canal including Marina Mall Pier 7. A trip packed with lots of fun, music and entertainment shows, breathtaking sightings and uncompromisingly healthy & tasty food is definitely worth the time!',
        description2: '',
        description3: '',
        description4: '',
        description5: '',
        description6: '',
    },
    {
        id: 3,
        image: '/images/TouristAttractions/3.webp',
        title: 'Megayacht',
        description1: '•	Book a mega yacht to Enjoy best Dubai scenic views of the famous Dubai Marina along with panoramic views of the skyline and Atlantis Hotel and Palm Jumeirah Island from the comfort of a luxury mega yacht. Sit back and taste a five star all-you-can-eat buffet dinner.',
        description2: '',
        description3: '',
        description4: '',
        description5: '',
        description6: ' ',
    },
    {
        id: 4,
        image: '/images/TouristAttractions/4.webp',
        title: 'Limousine',
        description1: '•	At NOON Limousine, your satisfaction and your pleasure is our absolute goal. When you choose chaffeur services in Dubai from noon Limousine, We cater to your every need. ',
        description2: '•	The staff and drivers of noon Limousine services in Dubai are completely committed to ensuring that you have a dream trip with our service. For those who are traveling for business, your trip will be smooth and rapidly accomplished',
        description3: ' ',
        description4: '',
        description5: '',
        description6: ' ',
    },
   
    {
        id: 5,
        image: '/images/TouristAttractions/5.webp',
        title: 'Jetski',
        description1: '•	Jetskiing is a water sport of high speed that is perfect for improving your balance, agility, and leg muscles.',
        description2: '•	There are many wonderful places where a great introduction to jetskiing can be received. ',
        description3: '•	Jetskiing, of all water sports, is perhaps the simplest to pick up. Its probably the fastest, too, and its the adrenaline added to that pace that makes the sport draw thousands of fans.',
        description4: '',
        description5: '',
        description6: ' ',
    },
    {
        id: 6,
        image: '/images/TouristAttractions/6.webp',
        title: 'Flyboard',
        description1: '•	Flyboarding, however, is an extreme water sport in which athletes compete using facilities called flyboards. As a personal watercraft fitted with a water jetpack/hoverboard, a flyboard can be better represented. The Flyboard is a PWC (Personal WaterCraft) accessory that allows a user to lift an individual up to 45 feet in the air using water jet propulsion technology once installed. It is by far the most enjoyable on the water you can have and is perfect for men or women between the ages of 15-70!',
        description2: '',
        description3: '',
        description4: '',
        description5: '',
        description6: '',
    },
    {
        id: 7,
        image: '/images/TouristAttractions/7.webp',
        title: 'Parasailing',
        description1: '•	Experience Dubai and see the beautiful skyline of Jumeirah Beach Residence from a different perspective on a parasailing activity your family & friends. Get aerial views of Dubai Marina and Palm Island as you soar over the Arabian Gulf.',
        description2: '',
        description3: '',
        description4: '',
        description5: '',
        description6: ' ',
    },
]

// our yachts data 
export const ourYachtsData = [
    {
        id: 1,
        image: '/images/Yachts/31FTRBC/1.webp',
        images:[
            '/images/Yachts/31FTRBC/1.webp',
            '/images/Yachts/31FTRBC/2.webp',
            '/images/Yachts/31FTRBC/3.webp',
            '/images/Yachts/31FTRBC/4.webp',
            '/images/Yachts/31FTRBC/5.webp',
            '/images/Yachts/31FTRBC/6.webp',
            '/images/Yachts/31FTRBC/7.webp',
            '/images/Yachts/31FTRBC/8.webp',
        ],
        title: 'Fishing Pearl',
        yachtLength: '31 FT',
        capacity: '8 PAX',
        feature1: 'Captain & 2 Crew',
    },
    {
        id: 2,
        image: '/images/Yachts/36FT RBC/1.webp',
        images:[
            '/images/Yachts/36FT RBC/1.webp',
            '/images/Yachts/36FT RBC/2.webp',
            '/images/Yachts/36FT RBC/3.webp',
            '/images/Yachts/36FT RBC/4.webp',
            '/images/Yachts/36FT RBC/5.webp',
            '/images/Yachts/36FT RBC/6.webp',
            '/images/Yachts/36FT RBC/7.webp',
            '/images/Yachts/36FT RBC/8.webp',
        ],
        title: 'Orxy',
        yachtLength: '40 FT',
        capacity: '8 PAX',
        feature1: 'Captain & 2 Crew',
    }, 
     {
        id: 3,
        image: '/images/Yachts/42FT RBC/1.webp',
        images:[
            '/images/Yachts/42FT RBC/1.webp',
            '/images/Yachts/42FT RBC/2.webp',
            '/images/Yachts/42FT RBC/3.webp',
            '/images/Yachts/42FT RBC/4.webp',
            '/images/Yachts/42FT RBC/5.webp',
            '/images/Yachts/42FT RBC/6.webp',
            '/images/Yachts/42FT RBC/7.webp',
            '/images/Yachts/42FT RBC/8.webp',
            '/images/Yachts/42FT RBC/9.webp',
            '/images/Yachts/42FT RBC/10.webp',
            '/images/Yachts/42FT RBC/11.webp',
            '/images/Yachts/42FT RBC/12.webp',
            '/images/Yachts/42FT RBC/13.webp',
            '/images/Yachts/42FT RBC/14.webp',
        ],
        title: 'Half Moon',
        yachtLength: '42 FT',
        capacity: '10 PAX',
        feature1: 'Captain & 2 Crew',
    },
    {
        id: 4,
        image: '/images/Yachts/48FT RBC/1.webp',
        images:[
            '/images/Yachts/48FT RBC/1.webp',
            '/images/Yachts/48FT RBC/2.webp',
            '/images/Yachts/48FT RBC/3.webp',
            '/images/Yachts/48FT RBC/4.webp',
            '/images/Yachts/48FT RBC/5.webp',
            '/images/Yachts/48FT RBC/6.webp',
            '/images/Yachts/48FT RBC/7.webp',
            '/images/Yachts/48FT RBC/8.webp',
            '/images/Yachts/48FT RBC/9.webp',
            '/images/Yachts/48FT RBC/10.webp',
            '/images/Yachts/48FT RBC/11.webp',
            '/images/Yachts/48FT RBC/12.webp',
        ],
        title: 'Blue Star',
        yachtLength: '48 FT',
        capacity: '11 PAX',
        feature1: 'Captain & 2 Crew',

    },
    {
        id: 5,
        image: '/images/Yachts/50FT RBC/1.webp',
        images:[
            '/images/Yachts/50FT RBC/1.webp',
            '/images/Yachts/50FT RBC/2.webp',
            '/images/Yachts/50FT RBC/3.webp',
            '/images/Yachts/50FT RBC/4.webp',
            '/images/Yachts/50FT RBC/5.webp',
            '/images/Yachts/50FT RBC/6.webp',
            '/images/Yachts/50FT RBC/7.webp',
            '/images/Yachts/50FT RBC/8.webp',
            '/images/Yachts/50FT RBC/9.webp',
            '/images/Yachts/50FT RBC/10.webp',
            '/images/Yachts/50FT RBC/11.webp',
            '/images/Yachts/50FT RBC/12.webp',
            '/images/Yachts/50FT RBC/13.webp',
            '/images/Yachts/50FT RBC/14.webp',
            '/images/Yachts/50FT RBC/15.webp',
        ],
        title: 'White Magic',
        yachtLength: '50 FT',
        capacity: '13 PAX',
        feature1: 'Captain & 2 Crew',
    }, 
    {
        id: 7,
        image: '/images/Yachts/56ft RBC/1.webp',
        images:[
            '/images/Yachts/56ft RBC/1.webp',
            '/images/Yachts/56ft RBC/2.webp',
            '/images/Yachts/56ft RBC/3.webp',
            '/images/Yachts/56ft RBC/4.webp',
            '/images/Yachts/56ft RBC/5.webp',
            '/images/Yachts/56ft RBC/6.webp',
            '/images/Yachts/56ft RBC/7.webp',
            '/images/Yachts/56ft RBC/8.webp',
            '/images/Yachts/56ft RBC/9.webp',
            '/images/Yachts/56ft RBC/10.webp',
            '/images/Yachts/56ft RBC/11.webp',
            '/images/Yachts/56ft RBC/12.webp',
        ],
        title: 'Final Destination',
        yachtLength: '56 FT',
        capacity: '15 PAX',
        feature1: 'Captain & 2 Crew',
    },
    {
        id: 8,
        image: '/images/Yachts/45ft mighty mnh/1.webp',
        images:[
            '/images/Yachts/45ft mighty mnh/1.webp',
            '/images/Yachts/45ft mighty mnh/2.webp',
            '/images/Yachts/45ft mighty mnh/3.webp',
            '/images/Yachts/45ft mighty mnh/4.webp',
            '/images/Yachts/45ft mighty mnh/5.webp',
            '/images/Yachts/45ft mighty mnh/6.webp',
        ],
        title: 'Mighty',
        yachtLength: '45 FT',
        capacity: '10 PAX',
        feature1: 'Captain & 2 Crew',
    },
    {
        id: 9,
        image: '/images/Yachts/65ft seaduction mnh/1.webp',
        images:[
            '/images/Yachts/65ft seaduction mnh/1.webp',
            '/images/Yachts/65ft seaduction mnh/2.webp',
            '/images/Yachts/65ft seaduction mnh/3.webp',
            '/images/Yachts/65ft seaduction mnh/4.webp',
            '/images/Yachts/65ft seaduction mnh/5.webp',
        ],
        title: 'Seaduction',
        yachtLength: '65 FT',
        capacity: '20 PAX',
        feature1: 'Captain & 2 Crew',
        feature2: 'xyz',
    },
    {
        id: 10,
        image: '/images/Yachts/75ft grace mnh/1.webp',
        images:[
            '/images/Yachts/75ft grace mnh/1.webp',
            '/images/Yachts/75ft grace mnh/2.webp',
            '/images/Yachts/75ft grace mnh/3.webp',
            '/images/Yachts/75ft grace mnh/4.webp',
            '/images/Yachts/75ft grace mnh/5.webp',
            '/images/Yachts/75ft grace mnh/6.webp',
            '/images/Yachts/75ft grace mnh/7.webp',
            '/images/Yachts/75ft grace mnh/8.webp',
            '/images/Yachts/75ft grace mnh/9.webp',
            '/images/Yachts/75ft grace mnh/10.webp',
            '/images/Yachts/75ft grace mnh/11.webp',
        ],
        title: 'Grace',
        yachtLength: '75 FT',
        capacity: '25 PAX',
        feature1: 'Captain & 2 Crew',
        feature2: 'xyz',
    },
    {
        id: 11,
        image: '/images/Yachts/alshali royal yacht 50ft/1.webp',
        images:[
            '/images/Yachts/alshali royal yacht 50ft/1.webp',
            '/images/Yachts/alshali royal yacht 50ft/2.webp',
            '/images/Yachts/alshali royal yacht 50ft/3.webp',
            '/images/Yachts/alshali royal yacht 50ft/4.webp',
            '/images/Yachts/alshali royal yacht 50ft/5.webp',
            '/images/Yachts/alshali royal yacht 50ft/6.webp',
            '/images/Yachts/alshali royal yacht 50ft/7.webp',
            '/images/Yachts/alshali royal yacht 50ft/8.webp',
        ],
        title: 'Alshali',
        yachtLength: '50 FT',
        capacity: '15 PAX',
        feature1: 'Captain & 2 Crew',
        feature2: 'xyz',
    },
    {
        id: 12,
        image: '/images/Yachts/alshali sport 50ft ruyal/1.webp',
        images:[
            '/images/Yachts/alshali sport 50ft ruyal/1.webp',
            '/images/Yachts/alshali sport 50ft ruyal/2.webp',
            '/images/Yachts/alshali sport 50ft ruyal/3.webp',
            '/images/Yachts/alshali sport 50ft ruyal/4.webp',
            '/images/Yachts/alshali sport 50ft ruyal/5.webp',
            '/images/Yachts/alshali sport 50ft ruyal/6.webp',
            '/images/Yachts/alshali sport 50ft ruyal/7.webp',
            '/images/Yachts/alshali sport 50ft ruyal/8.webp',
        ],
        title: 'Alshali Sport',
        yachtLength: '50 FT',
        capacity: '15 PAX',
        feature1: 'Captain & 2 Crew',
    },
    {
        id: 13,
        image: '/images/Yachts/arabian pearl/1.webp',
        images:[
            '/images/Yachts/arabian pearl/1.webp',
            '/images/Yachts/arabian pearl/2.webp',
            '/images/Yachts/arabian pearl/3.webp',
            '/images/Yachts/arabian pearl/4.webp',
            '/images/Yachts/arabian pearl/5.webp',
        ],
        title: 'Arabian Pearl',
        yachtLength: '90 FT',
        capacity: '45 PAX',
        feature1: 'Captain & 2 Crew',
    },
    {
        id: 14,
        image: '/images/Yachts/as marine faisaal silfar/1.webp',
        images:[
            '/images/Yachts/as marine faisaal silfar/1.webp',
            '/images/Yachts/as marine faisaal silfar/2.webp',
            '/images/Yachts/as marine faisaal silfar/3.webp',
            '/images/Yachts/as marine faisaal silfar/4.webp',
            '/images/Yachts/as marine faisaal silfar/5.webp',
            '/images/Yachts/as marine faisaal silfar/6.webp',
            '/images/Yachts/as marine faisaal silfar/7.webp',
            '/images/Yachts/as marine faisaal silfar/8.webp',
            '/images/Yachts/as marine faisaal silfar/9.webp',
            '/images/Yachts/as marine faisaal silfar/10.webp',
        ],
        title: 'As Marine',
        yachtLength: '70 FT',
        capacity: '20 PAX',
        feature1: 'Captain & 2 Crew',
    },
    {
        id: 15,
        image: '/images/Yachts/azzimut 52ft royal/1.webp',
        images:[
            '/images/Yachts/azzimut 52ft royal/1.webp',
            '/images/Yachts/azzimut 52ft royal/2.webp',
            '/images/Yachts/azzimut 52ft royal/3.webp',
            '/images/Yachts/azzimut 52ft royal/4.webp',
            '/images/Yachts/azzimut 52ft royal/5.webp',
            '/images/Yachts/azzimut 52ft royal/6.webp',
            '/images/Yachts/azzimut 52ft royal/7.webp',
            '/images/Yachts/azzimut 52ft royal/8.webp',
            '/images/Yachts/azzimut 52ft royal/9.webp',
        ],
        title: 'Azzimut',
        yachtLength: '52 FT',
        capacity: '15 PAX',
        feature1: 'Captain & 2 Crew',
    },
    {
        id: 16,
        image: '/images/Yachts/b ricon 68ft  royal yachts/1.webp',
        images:[
            '/images/Yachts/b ricon 68ft  royal yachts/1.webp',
            '/images/Yachts/b ricon 68ft  royal yachts/2.webp',
            '/images/Yachts/b ricon 68ft  royal yachts/3.webp',
            '/images/Yachts/b ricon 68ft  royal yachts/4.webp',
            '/images/Yachts/b ricon 68ft  royal yachts/5.webp',
            '/images/Yachts/b ricon 68ft  royal yachts/6.webp',
            '/images/Yachts/b ricon 68ft  royal yachts/7.webp',
            '/images/Yachts/b ricon 68ft  royal yachts/8.webp',
            '/images/Yachts/b ricon 68ft  royal yachts/9.webp',
        ],
        title: 'B Ricon',
        yachtLength: '68 FT',
        capacity: '20 PAX',
        feature1: 'Captain & 2 Crew',
    },
    {
        id: 17,
        image: '/images/Yachts/big daddy 95ft royal yachts/1.webp',
        images:[
            '/images/Yachts/big daddy 95ft royal yachts/1.webp',
            '/images/Yachts/big daddy 95ft royal yachts/2.webp',
            '/images/Yachts/big daddy 95ft royal yachts/3.webp',
            '/images/Yachts/big daddy 95ft royal yachts/4.webp',
            '/images/Yachts/big daddy 95ft royal yachts/5.webp',
            '/images/Yachts/big daddy 95ft royal yachts/6.webp',
            '/images/Yachts/big daddy 95ft royal yachts/7.webp',
            '/images/Yachts/big daddy 95ft royal yachts/8.webp',
            '/images/Yachts/big daddy 95ft royal yachts/9.webp',
            '/images/Yachts/big daddy 95ft royal yachts/10.webp',
            '/images/Yachts/big daddy 95ft royal yachts/11.webp',
        ],
        title: 'Big Daddy',
        yachtLength: '95 FT',
        capacity: '45 PAX',
        feature1: 'Captain & 2 Crew',
    },
    {
        id: 18,
        image: '/images/Yachts/black pearl 90ft royal yachts/1.webp',
        images:[
            '/images/Yachts/black pearl 90ft royal yachts/1.webp',
            '/images/Yachts/black pearl 90ft royal yachts/2.webp',
            '/images/Yachts/black pearl 90ft royal yachts/3.webp',
            '/images/Yachts/black pearl 90ft royal yachts/4.webp',
            '/images/Yachts/black pearl 90ft royal yachts/5.webp',
            '/images/Yachts/black pearl 90ft royal yachts/6.webp',
            '/images/Yachts/black pearl 90ft royal yachts/7.webp',
            '/images/Yachts/black pearl 90ft royal yachts/8.webp',
            '/images/Yachts/black pearl 90ft royal yachts/9.webp',
            '/images/Yachts/black pearl 90ft royal yachts/10.webp',
            '/images/Yachts/black pearl 90ft royal yachts/11.webp',
            '/images/Yachts/black pearl 90ft royal yachts/12.webp',
        ],
        title: 'Black Pearl',
        yachtLength: '90 FT',
        capacity: '40 PAX',
        feature1: 'Captain & 2 Crew',
    },
    {
        id: 19,
        image: '/images/Yachts/blue moon 55ft mnh/1.webp',
        images:[
            '/images/Yachts/blue moon 55ft mnh/1.webp',
            '/images/Yachts/blue moon 55ft mnh/2.webp',
            '/images/Yachts/blue moon 55ft mnh/3.webp',
            '/images/Yachts/blue moon 55ft mnh/4.webp',
            '/images/Yachts/blue moon 55ft mnh/5.webp',
            '/images/Yachts/blue moon 55ft mnh/6.webp',
            '/images/Yachts/blue moon 55ft mnh/7.webp',
            '/images/Yachts/blue moon 55ft mnh/8.webp',
        ],
        title: 'Blue Moon',
        yachtLength: '55 FT',
        capacity: '15 PAX',
        feature1: 'Captain & 2 Crew',
    },
    {
        id: 20,
        image: '/images/Yachts/conway alshali 55ft/1.webp',
        images:[
            '/images/Yachts/conway alshali 55ft/1.webp',
            '/images/Yachts/conway alshali 55ft/2.webp',
            '/images/Yachts/conway alshali 55ft/3.webp',
            '/images/Yachts/conway alshali 55ft/4.webp',
            '/images/Yachts/conway alshali 55ft/5.webp',
            '/images/Yachts/conway alshali 55ft/6.webp',
        ],
        title: 'C Alshali',
        yachtLength: '55 FT',
        capacity: '15 PAX',
        feature1: 'Captain & 2 Crew',
    },
    {
        id: 21,
        image: '/images/Yachts/conwy craft 34ft/1.webp',
        images:[
            '/images/Yachts/conwy craft 34ft/1.webp',
            '/images/Yachts/conwy craft 34ft/2.webp',
            '/images/Yachts/conwy craft 34ft/3.webp',
            
        ],
        title: 'C Craft',
        yachtLength: '34 FT',
        capacity: '10 PAX',
        feature1: 'Captain & 2 Crew',
    },
    {
        id: 22,
        image: '/images/Yachts/conwy silver craft 27ft/1.webp',
        images:[
            '/images/Yachts/conwy silver craft 27ft/1.webp',
            '/images/Yachts/conwy silver craft 27ft/2.webp',
            '/images/Yachts/conwy silver craft 27ft/3.webp',
            '/images/Yachts/conwy silver craft 27ft/4.webp',
            '/images/Yachts/conwy silver craft 27ft/5.webp',
        ],
        title: 'C Silver Craft',
        yachtLength: '27 FT',
        capacity: '6 PAX',
        feature1: 'Captain & 2 Crew',
    },
    {
        id: 24,
        image: '/images/Yachts/dessert rose/1.webp',
        images:[
            '/images/Yachts/dessert rose/1.webp',
            '/images/Yachts/dessert rose/2.webp',
            '/images/Yachts/dessert rose/3.webp',
            '/images/Yachts/dessert rose/4.webp',
            '/images/Yachts/dessert rose/5.webp',
            '/images/Yachts/dessert rose/6.webp',
            '/images/Yachts/dessert rose/7.webp',
            '/images/Yachts/dessert rose/8.webp',
        ],
        title: 'Dessert Rose',
        yachtLength: '155 FT',
        capacity: '250 PAX',
        feature1: 'Captain & 2 Crew',
    },
    {
        id: 25,
        image: '/images/Yachts/gugu boat/1.webp',
        images:[
            '/images/Yachts/gugu boat/1.webp',
            '/images/Yachts/gugu boat/2.webp',
            '/images/Yachts/gugu boat/3.webp',
            '/images/Yachts/gugu boat/4.webp',
            '/images/Yachts/gugu boat/5.webp',
            '/images/Yachts/gugu boat/6.webp',
            '/images/Yachts/gugu boat/7.webp',
            '/images/Yachts/gugu boat/8.webp',
            '/images/Yachts/gugu boat/9.webp',
            '/images/Yachts/gugu boat/10.webp',
        ],
        title: 'GuGu Boat',
        yachtLength: '110 FT',
        capacity: '80 PAX',
        feature1: 'Captain & 2 Crew',
    },
    {
        id: 26,
        image: '/images/Yachts/gulf craft 55ft royal/1.webp',
        images:[
            '/images/Yachts/gulf craft 55ft royal/1.webp',
            '/images/Yachts/gulf craft 55ft royal/2.webp',
            '/images/Yachts/gulf craft 55ft royal/3.webp',
            '/images/Yachts/gulf craft 55ft royal/4.webp',
            '/images/Yachts/gulf craft 55ft royal/5.webp',
            '/images/Yachts/gulf craft 55ft royal/6.webp',
        ],
        title: 'Gulf Craft',
        yachtLength: '55 FT',
        capacity: '15 PAX',
        feature1: 'Captain & 2 Crew',
    },
    {
        id: 27,
        image: '/images/Yachts/jasmine 55ft royal/1.webp',
        images:[
            '/images/Yachts/jasmine 55ft royal/1.webp',
            '/images/Yachts/jasmine 55ft royal/2.webp',
            '/images/Yachts/jasmine 55ft royal/3.webp',
            '/images/Yachts/jasmine 55ft royal/4.webp',
            '/images/Yachts/jasmine 55ft royal/5.webp',
            '/images/Yachts/jasmine 55ft royal/6.webp',
            '/images/Yachts/jasmine 55ft royal/7.webp',
            '/images/Yachts/jasmine 55ft royal/8.webp',
            '/images/Yachts/jasmine 55ft royal/9.webp',
            '/images/Yachts/jasmine 55ft royal/10.webp',
            '/images/Yachts/jasmine 55ft royal/11.webp',
        ],
        title: 'Jasmine',
        yachtLength: '55 FT',
        capacity: '15 PAX',
        feature1: 'Captain & 2 Crew',
        feature2: 'xyz',
    },
    {
        id: 28,
        image: '/images/Yachts/legacy mnh 34ft/1.webp',
        images:[
            '/images/Yachts/legacy mnh 34ft/1.webp',
            '/images/Yachts/legacy mnh 34ft/2.webp',
            '/images/Yachts/legacy mnh 34ft/3.webp',
            '/images/Yachts/legacy mnh 34ft/4.webp',
            '/images/Yachts/legacy mnh 34ft/5.webp',
            '/images/Yachts/legacy mnh 34ft/6.webp',
        ],
        title: 'Legacy',
        yachtLength: '34 FT',
        capacity: '10 PAX',
        feature1: 'Captain & 2 Crew',
    },
    {
        id: 29,
        image: '/images/Yachts/lotus/1.webp',
        images:[
            '/images/Yachts/lotus/1.webp',
            '/images/Yachts/lotus/2.webp',
            '/images/Yachts/lotus/3.webp',
            '/images/Yachts/lotus/4.webp',
            '/images/Yachts/lotus/5.webp',
            '/images/Yachts/lotus/6.webp',
            '/images/Yachts/lotus/7.webp',
            '/images/Yachts/lotus/8.webp',
            '/images/Yachts/lotus/9.webp',
            '/images/Yachts/lotus/10.webp',
            '/images/Yachts/lotus/11.webp',
        ],
        title: 'Lotus',
        yachtLength: '240 FT',
        capacity: '500 PAX',
        feature1: 'Captain & 2 Crew',
    },
    {
        id: 30,
        image: '/images/Yachts/majesty 56ft royal/1.webp',
        images:[
            '/images/Yachts/majesty 56ft royal/1.webp',
            '/images/Yachts/majesty 56ft royal/2.webp',
            '/images/Yachts/majesty 56ft royal/3.webp',
            '/images/Yachts/majesty 56ft royal/4.webp',
            '/images/Yachts/majesty 56ft royal/5.webp',
            '/images/Yachts/majesty 56ft royal/6.webp',
            '/images/Yachts/majesty 56ft royal/7.webp',
            '/images/Yachts/majesty 56ft royal/8.webp',
        ],
        title: 'Majesty',
        yachtLength: '56 FT',
        capacity: '16 PAX',
        feature1: 'Captain & 2 Crew',
    },
    {
        id: 31,
        image: '/images/Yachts/monalisa/1.webp',
        images:[
            '/images/Yachts/monalisa/1.webp',
            '/images/Yachts/monalisa/2.webp',
            '/images/Yachts/monalisa/3.webp',
            '/images/Yachts/monalisa/4.webp',
            '/images/Yachts/monalisa/5.webp',
            '/images/Yachts/monalisa/6.webp',
            '/images/Yachts/monalisa/7.webp',
            '/images/Yachts/monalisa/8.webp',
            '/images/Yachts/monalisa/9.webp',
            '/images/Yachts/monalisa/10.webp',
            '/images/Yachts/monalisa/11.webp',
            '/images/Yachts/monalisa/12.webp',
            '/images/Yachts/monalisa/13.webp',
            '/images/Yachts/monalisa/14.webp',
        ],
        title: 'Monalisa',
        yachtLength: '150 FT',
        capacity: '95 PAX',
        feature1: 'Captain & 2 Crew',
        feature2: 'xyz',
    },
    {
        id: 32,
        image: '/images/Yachts/ocean pearl/1.webp',
        images:[
            '/images/Yachts/ocean pearl/1.webp',
            '/images/Yachts/ocean pearl/2.webp',
            '/images/Yachts/ocean pearl/3.webp',
            '/images/Yachts/ocean pearl/4.webp',
            '/images/Yachts/ocean pearl/5.webp',
            '/images/Yachts/ocean pearl/6.webp',
            '/images/Yachts/ocean pearl/7.webp',
        ],
        title: 'Ocean Pearl',
        yachtLength: '85 FT',
        capacity: '45 PAX',
        feature1: 'Captain & 2 Crew',
    },
    {
        id: 33,
        image: '/images/Yachts/ricon 64ft royal/1.webp',
        images:[
            '/images/Yachts/ricon 64ft royal/1.webp',
            '/images/Yachts/ricon 64ft royal/2.webp',
            '/images/Yachts/ricon 64ft royal/3.webp',
            '/images/Yachts/ricon 64ft royal/4.webp',
            '/images/Yachts/ricon 64ft royal/5.webp',
            '/images/Yachts/ricon 64ft royal/6.webp',
            '/images/Yachts/ricon 64ft royal/7.webp',
        ],
        title: 'Ricon',
        yachtLength: '64 FT',
        capacity: '15 PAX',
        feature1: 'Captain & 2 Crew',
    },
    {
        id: 34,
        image: '/images/Yachts/virgo/1.webp',
        images:[
            '/images/Yachts/virgo/1.webp',
            '/images/Yachts/virgo/2.webp',
            '/images/Yachts/virgo/3.webp',
            '/images/Yachts/virgo/4.webp',
            '/images/Yachts/virgo/5.webp',
            '/images/Yachts/virgo/6.webp',
            '/images/Yachts/virgo/7.webp',
            '/images/Yachts/virgo/8.webp',
            '/images/Yachts/virgo/9.webp',
            '/images/Yachts/virgo/10.webp',
            '/images/Yachts/virgo/11.webp',
            '/images/Yachts/virgo/12.webp',
            '/images/Yachts/virgo/13.webp',
            '/images/Yachts/virgo/14.webp',
            '/images/Yachts/virgo/15.webp',
            '/images/Yachts/virgo/16.webp',
            '/images/Yachts/virgo/17.webp',
        ],
        title: 'virgo',
        yachtLength: '85 FT',
        capacity: '40 PAX',
        feature1: 'Captain & 2 Crew',
    },
    
]


    
// master detail flow function of detail page
export const getDataById = (id) => {
    return ourYachtsData.find(finding => finding.id === Number(id));
}



// home carousel images
export const homeCarouselImages = [
    '/images/carousel-images/1.webp',
    '/images/carousel-images/2.webp',
    '/images/carousel-images/3.webp',
    '/images/carousel-images/4.webp',
]