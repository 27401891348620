import React from 'react'
import {
    Grid,
    Container,
    Card,
    CardContent,
    Typography
} from "@material-ui/core";
import Slider from "react-slick";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useParams } from "react-router-dom";
import { getDataById } from "../../data";
import { Helmet } from "react-helmet";
import IconButton from "@material-ui/core/IconButton";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import CallIcon from '@material-ui/icons/Call';

const useStyles = makeStyles({
    detailSection: {
        marginTop: '50px'
    },
    image: {
        width: '100%'
    },
    detailHeading: {
        color: 'black'
    },
    carouselImage: {
        height: '400px'
    },
    icons: {
        color: 'black',
        '&:hover': {
            backgroundColor: 'crimson',
            color: 'whitesmoke'
        }
    }
})

function Detail() {
    const classes = useStyles();
    const { id } = useParams();
    const data = getDataById(id);
    const images = getDataById(id);
    const settings = {
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        dots: true,
        pauseOnDotsHover: true
    }

    return (
        <>
            <Helmet>
                <title>Noon Yacht | Detail</title>
                <meta name="description" content="Noon Yacht is Dubai's Favorite Award Winning Yacht Rental Dubai Company, Book Online." />
                <meta name="keywords" content="Noon Yacht dubai,Noon Yacht,yachts,xclusive yachts,yachts in dubai marina,princess yachts,marina yachts,
        dubai marina yachts,luxury yachts,majesty yachts,azimut yachts,royal yachts,yachts dubai,luxurious yachts,
        yachts in dubai,yachts for rent,cozmo yachts,superyacht,boat charter,mega yacht,yacht charter,sailing yacht
        yacht broker,birthday parties on yachts,birthday yachts,birthday in yacht,happy birthday yachts,birthday party in yacht dubai,
        wedding yachts miami,wedding on a yacht dubai,wedding charter yachts,wedding yachts in miami,wedding yacht dubai,
        mega yachts dubai,mega yachts for charter,mega yachts and superyachts,fishing in dubai,fishing abu dhabiyachts rental dubai,yachts rental,yachts rental near me,yachts rental in miami,adonia yachts rental lake powell,
adonia yachts rental,ariel yachts rental,azimut yachts rental,a yacht rental,yachts rental miami beach,yachts and boats rental dubai,nanje rental yachts dubai,
yachts to rent in dubai,yacht rental uae,yachts for rental,yachts for rental near me,yachts for rental in miami,yachts for rental in dubai,
luxury yachts for rental,yachts rental in dubai,yachts rental in abu dhabi,yacht rent dubai,yacht charters dubai,luxury yachts rental,
yachts rental miami,luxury yachts rental miami,luxury yachts rental marina,mega yachts rental,yacht rent in abu dhabi,party yacht rental,yachts rentals,
yachts rentals near me,yachts rentals dubai,vacation rental yachts,wajer yachts rental,xclusive yachts rental dubai,yacht booking abu dhabi,yacht to rent dubai,
yachts dubai rent" />
            </Helmet>
            <Container className={classes.detailSection} >
                <Grid container spacing={4} >
                    <Grid item xs={12} sm={12} md={6} lg={6} >
                        <Slider {...settings} > {
                            data.images.map(images =>
                                <img className={classes.carouselImage} src={images} alt="Noon Yacht" />
                            )
                        }
                        </Slider>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} >
                        <Card style={{ backgroundColor: '#fada5e' }}>
                            <CardContent >
                                <Typography
                                    gutterBottom
                                    className={classes.detailHeading}
                                    variant='h4'
                                > {data.title} </Typography>
                                <Typography gutterBottom variant='p' > Length: {data.yachtLength} </Typography>
                                <br />
                                <Typography gutterBottom variant='p' > Capacity: {data.capacity} </Typography>
                                <br />
                                <Typography gutterBottom variant='p' > {data.feature1} </Typography> <br />

                                <Typography
                                    gutterBottom
                                    className={classes.detailSection}
                                    variant='h5' > Inclusions: </Typography>
                                <li >
                                    <Typography gutterBottom variant='p' > Free Soft Drinks </Typography> <br />
                                </li>
                                <li >
                                    <Typography gutterBottom variant='p' > Water & Ice </Typography> <br />
                                </li>
                                <li >
                                    <Typography gutterBottom variant='p' > Swimming Fishing Equipment (Additional charges) </Typography> <br />
                                </li>
                                <li >
                                    <Typography gutterBottom variant='p' >  BBQ Grill (Additional charges) </Typography> <br />
                                </li>
                                <li >
                                    <Typography gutterBottom variant='p' >  Fresh Towels </Typography> <br />
                                </li>
                                <li >
                                    <Typography gutterBottom variant='p' >  Life Jackets </Typography> <br />
                                </li>
                                <Typography
                                    gutterBottom
                                    className={classes.detailSection}
                                    variant='h5' > For Booking: </Typography>
                                
                                    <ul>
                                    <li>
                                    <a>
                                    <IconButton
                                        target="_blank"
                                        href='https://wa.me/+971545850896'
                                        className={classes.icons}
                                    >
                                        <WhatsAppIcon />
                                        (+971) 54 585 0896
                                    </IconButton>
                                    </a>
                                    </li>
                                    
                                    <li>
                                    <a>
                                    <IconButton
                                    className={classes.icons}
                                    href="tel:+971545850896" >
                                     <CallIcon />
                                     054 585 0896
                                    </IconButton>
                                     </a>
                                    </li>

                                </ul>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default Detail