import React, { useState, useEffect } from 'react';
import Header from "./components/header";
import Home from "./pages/home";
import Footer from "./components/footer";
import { Switch, Route } from "react-router-dom";
import OurYachts from "./pages/our-yachts";
import TouristAttractions from "./pages/Tourist-attractions";
import Specialoffers from "./pages/services";
import Detail from "./pages/detail";
import ContactUs from "./pages/contact-us";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import "./App.css"

function App() {

    return (
            <div style={{ overflow: 'hidden' }}>
                <Header />
                <Switch >
                    <Route exact path='/' component={Home} />
                    <Route exact path='/yachts' component={OurYachts} />
                    <Route exact path='/Specialoffers' component={Specialoffers} />
                    <Route exact path='/TouristAttractions' component={TouristAttractions} />
                    <Route exact path='/detail/:id' component={Detail} />
                    <Route exact path='/contact-us' component={ContactUs} />
                </Switch>
                <Footer />
                <a
                href="https://wa.me/+971567033102"
                class="whatsapp_float"
                target="_blank"
                rel="noopener noreferrer"
            >
            <WhatsAppIcon />
            </a>
            </div>
    )
}

export default App;